import { SESION, SSO_API_URL } from "@/constants";
import useStore from "@/store/main/action";
import { customFetch, handleRedirectSSO } from "@/utils/Auth";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export const Component = () => {
	const { setUser, setUserInfo, setIsLogged} = useStore();

	const handleValidaSesion = async () => {
		try {
			const { response } = await customFetch({
				url: `${SSO_API_URL}/Valida/Sesion`,
				method: 'POST',
				headers: { Authorization: `Bearer ${localStorage.getItem('SSO_CREDENTIALS') ?? ''}` },
			});

			if (!response.Success || response.Success && (response.Data as any).sesionNotFound) {
				setIsLogged(false);
				throw 'Sesión Inválida';
			}

			setUser(response.Data);
			setUserInfo(response.Data);
			setIsLogged(true);
		} catch (error) {
			handleRedirectSSO();
		}
    };

	useEffect(() => {
		
        handleValidaSesion();

		const intervalo = setInterval(handleValidaSesion, ( SESION ?? 15 ) * 60 * 1000);

		return () => clearInterval(intervalo);

	}, []);

	return (
		<Outlet />
	);
}

export { Component as ProtectedRoute }
